<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getUtentiList')">
        <div class="row">
          <div class="col-sm-4 filter">
            <input
              placeholder="Nome utente"
              name="Nome_utente"
              type="text"
              class="form-control"
              @input="setNomeUtente"
              :value="nomeUtente"
            />
          </div>
          <div class="col-sm-4 filter">
            <input
              placeholder="Cognome"
              name="cognome"
              type="text"
              class="form-control"
              @input="setCognome"
              :value="cognome"
            />
          </div>
        </div>

        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getUtentiList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "filtriUtentiOmologatori",
  emits: ["resetFilters", "getUtentiList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {},

  setup() {
    const store = useStore();

    const nomeUtente = computed(
      () => store.getters.nomeUtenteUtentiOmologatori
    );
    const cognome = computed(() => store.getters.cognomeUtentiOmologatori);

    const setNomeUtente = (event) => {
      store.commit("setNomeUtenteUtentiOmologatori", event.target.value);
    };
    const setCognome = (event) => {
      store.commit("setCognomeUtentiOmologatori", event.target.value);
    };

    return {
      isActive: false,
      nomeUtente,
      cognome,
      setNomeUtente,
      setCognome,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedutenti_omologatori_list")
      ),
    };
  },
});
</script>

<style></style>
