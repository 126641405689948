<template>
  <div>
    <FilterUsersOmologatori
      @getUtentiList="getResetPage"
      @resetFilters="resetFilters"
    />
    <TableUsersOmologatori
      @getUtentiList="getUtentiList"
      @bloccaUtente="$emit('bloccaUtente', $event)"
      @sbloccaUtente="$emit('sbloccaUtente', $event)"
      @resetPsw="$emit('resetPsw', $event)"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";
import { useStore } from "vuex";
import FilterUsersOmologatori from "@/components/components-fit/gestione/utenti/FiltriUsersOmologatori.vue";
import TableUsersOmologatori from "@/components/components-fit/gestione/utenti/TableUsersOmologatori.vue";

export default defineComponent({
  name: "utenti-persone",
  emits: ["bloccaUtente", "sbloccaUtente", "resetPsw"],
  components: {
    FilterUsersOmologatori,
    TableUsersOmologatori,
  },
  props: {
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Utenze abilitate");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const nomeUtenteUtentiOmologatori = computed(
      () => store.getters.nomeUtenteUtentiOmologatori
    );
    const cognomeUtentiOmologatori = computed(
      () => store.getters.cognomeUtentiOmologatori
    );

    const rowsToSkip = computed(
      () => store.getters.rowsToSkipUtentiOmologatori
    );
    const fetchRows = computed(() => store.getters.fetchRowsUtentiOmologatori);
    const sortColumn = computed(
      () => store.getters.sortColumnUtentiOmologatori
    );
    const sortOrder = computed(() => store.getters.sortOrderUtentiOmologatori);

    const getUtentiList = () => {
      const localKeys = {
        tipo: "omologatori",
        nome_utente: trimInput(nomeUtenteUtentiOmologatori.value),
        cognome: trimInput(cognomeUtentiOmologatori.value),
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.UTENTI_OMOLOGATORI_LIST,
        itemName: "utenti_omologatori_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersUtentiOmologatori");
      getUtentiList();
    };
    getUtentiList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedutenti_omologatori_list")
    );
    const getResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipUtentiOmologatori");
      getUtentiList();
    };

    watch(
      () => props.refresh,
      (value) => {
        if (value == true) {
          getUtentiList();
        }
      }
    );

    return {
      getUtentiList,
      resetFilters,
      getResetPage,
    };
  },
});
</script>
